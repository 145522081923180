import store from '../store/store';

export function openSuccessAlert() {
    console.log("hello")
}

export function openErrorAlert(message = 'Internal Server Error') {
    message = message === 'Internal Server Error' ||
        !message ?
        'Unexpected error getting the information' :
        message;
    store.commit('openAlert', { type: 'error', message })
}

export function closeAlert() {
    store.commit('closeAlert');
}