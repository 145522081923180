import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    alert: {
      open: false,
      type: 'success',
      message: 'here goes the message',
    },
    toolsForm: {},
  },
  mutations: {
    openAlert(state, alert) {
      state.alert = { ...alert, open: true };
    },
    closeAlert(state) {
      state.alert.open = false;
    },
    setFormState(state, formState) {
      state.toolsForm = formState;
    },
  },
});
