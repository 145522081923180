export default class Tool {
  static SampleSizeCaculator = new Tool("sample-size-calculator");
  static DataExploration = new Tool("data-exploration");
  static AptivaAnalysisXML = new Tool("aptiva-analysis-xml");
  static AptivaWeeklyLogs = new Tool("aptiva-weekly-logs");
  static AptivaPrecisionAnalysis = new Tool("aptiva-precision-analysis");
  static AptivaLXBParser = new Tool("aptiva-lxb-parser");
  static MLLinearRegression = new Tool("ML-linear-regression");
  static QUANTAFlashAcceleratedStability = new Tool(
    "QUANTA-flash-accelerated-stability"
  );
  static QUANTAFlashOnboardStability = new Tool(
    "QUANTA-flash-onboard-stability"
  );
  static ROCcurves = new Tool("ROC-curves");
  static MachineLearningClassification = new Tool(
    "machine-learning-classification"
  );
  static PrincipalComponentAnalysis = new Tool("principal-component-analysis");
  static tDistributedStochasticNeighborEmbedding = new Tool(
    "tDistributed-stochastic-neighbor-embedding"
  );
  static HierarchicalClusteringDendrogram = new Tool(
    "hierarchical-clustering-dendrogram"
  );
  static Heatmap = new Tool("heatmap");
  static VennDiagram2 = new Tool("venn-diagram-2");
  static VennDiagram3 = new Tool("venn-diagram-3");
  static UpsetPlot = new Tool("upset-plot");
  static DiscriminationBetweenGroups = new Tool(
    "discrimination-between-groups"
  );
  static InstrumentPlacementMap = new Tool("instrument-placement-map");
  static BivariateScatterPlot = new Tool("bivariate-scatter-plot");
  static DotPlot = new Tool("dot-plot");
  static BoxPlot = new Tool("box-plot");
  static SwarmPlot = new Tool("swarm-plot");
  static ViolinPlot = new Tool("violin-plot");
  static DotPlotViolinPlot = new Tool("dot-plot-violin-plot");
  static PostTestProbabilities = new Tool("post-test-probabilities");
  static Correlogram = new Tool("correlogram");
  static VolcanoPlot = new Tool("volcano-plot");
  static RidgePlot = new Tool("ridge-plot");
  static AptivaUsageReportConversion = new Tool(
    "aptiva-usage-report-conversion"
  );
  static BiomarkersEvaluation = new Tool("biomarkers-eval");
  static AptivaDatabase = new Tool("aptiva-database");
  static AptivaImages = new Tool("aptiva-images");
  static SankeyPlot = new Tool("sankey-plot");

  constructor(name) {
    this.name = name;
  }
}
