import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import './styles/styles.css';
import vuetify from './plugins/vuetify';
import VuePapaParse from 'vue-papa-parse';
import toolsModule from './modules/tools';
import { registerModules } from './register-modules';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import './styles/tailwind.css';
import PortalVue from 'portal-vue';

Vue.use(PortalVue);

registerModules({
  tools: toolsModule,
});

Vue.use(VuePapaParse);

Vue.use(Toast, {
  position: 'bottom-left',
});

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
