<template>
  <transition name="bounce">
    <div class="alert-container" v-if="alert.open">
      <v-alert :type="alert.type"
        >{{ alert.message }}</v-alert
      >
    </div>
  </transition>
</template>

<script>
import { closeAlert } from '../../utils/alert-funcitons'
export default {
  name: "Alert",
  watch:{
    alert(value){
      if(value.open) {
        setTimeout(() => {
          closeAlert();
        }, 4000)
      }
    }
  },
  computed: {
    alert: {
      get() {
        return this.$store.state.alert;
      },
    },
  },
};
</script>

<style scoped>
.alert-container {
  width: fit-content;
  position: fixed;
  z-index: 2000;
  margin: 2rem;
  right: 0;
}
</style>