<template>
  <div>
    <v-app-bar app class="navbar">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="btn-menu"
      ></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <router-link class="title" :to="{ name: 'tools'}">DataLab</router-link>
      </div>

      <v-spacer></v-spacer>

      <v-btn-toggle
        v-model="option"
        tile
        group
        light
        mandatory
        class="nav-menu"
      >
        <v-btn value="tools" text class="btn-navbar" @click="goTo('tools')">
          Tools
        </v-btn>
        <v-btn
          value="templates"
          text
          class="btn-navbar"
          @click="goTo('templates')"
        >
          Templates
        </v-btn>
        <v-btn
          value="classroom"
          text
          class="btn-navbar"
          @click="goTo('classroom')"
        >
          Classroom
        </v-btn>
        <a 
          href="https://colab.research.google.com/github/InovaDx/public/" 
          style="underline: none; text-decoration: none; color: var(--primary-text-bg-color) !important; font-size: 16px; text-transform: capitalize;"
        >
          <v-btn value="inovaCollaboratory" text class="btn-navbar"> 
            Inova Colaboratory
          </v-btn> 
        </a>
        <v-btn value="about" text class="btn-navbar" @click="goTo('about')">
          About
        </v-btn>
      </v-btn-toggle>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group v-model="option" color="orange darken-2" group>
          <v-list-item @click="goTo('tools')" value="tools">
            <v-list-item-title>Tools</v-list-item-title>
          </v-list-item>

          <v-list-item @click="goTo('templates')" value="templates">
            <v-list-item-title>Templates</v-list-item-title>
          </v-list-item>

          <v-list-item @click="goTo('classroom')" value="classroom">
            <v-list-item-title>Classroom</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Inova Collaboratory</v-list-item-title>
          </v-list-item>

          <v-list-item @click="goTo('about')" value="about">
            <v-list-item-title>About</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data: () => ({
    drawer: false,
  }),
  computed: {
    option: {
      get() {
        return this.$route.name;
      },
      set(value) {
        return value;
      },
    }
  },
  methods: {
    goTo(page) {
      if (this.option !== page) this.$router.push(`/${page}`);
    },
  },
};
</script>

<style scoped>
.btn-menu {
  display: none;
}

.btn-navbar {
  color: var(--primary-text-bg-color) !important;
  text-transform: capitalize;
  font-size: 16px;
}

.navbar {
  background: var(--secondary-bg-color) !important;
}

.nav-menu {
  display: initial;
}

.title {
  color: var(--primary-bg-color);
  text-decoration: none;
  font-size: 24px;
}

.v-btn-toggle > .v-btn.v-btn--active {
  background-color: transparent !important;
  font-weight: 600;
  color: var(--primary-bg-color) !important;
  border-bottom: var(--primary-bg-color) 1px solid;
}

.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  display: none;
}
@media (max-width: 690px) {
  .btn-menu {
    display: initial;
  }

  .nav-menu {
    display: none;
  }
}
</style>