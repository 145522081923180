<template>
  <v-app id="app">
    <Navbar />
    <v-main>
      <Alert />
      <div class="card">
        <div class="card-container">
          <router-view />
        </div>
      </div>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Alert from "./components/alerts/Alert"
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    Alert
  },
};
</script>

<style scoped>
#app {
  background-color: unset;
}

.card {
  background-color: white;
  margin: 3rem 5rem;
  border-radius: 4px;
  min-height: 400px;
  overflow: auto;
}

.card-container {
  margin: 2.5rem 0.5rem;
}

@media (max-width: 1300px) {
  .card {
    margin: 3rem 1.5rem;
  }
}
</style>
