<template>
<v-footer class="footer">
    <img class="logo" src="../../assets/AF_WERFEN_BLUE_POS_RGB.png" alt="Werfen logo">
  </v-footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>
.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-bg-color) !important;
}

.logo{
    width: 170px;
    height: 53px;
}
</style>